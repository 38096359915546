import getLangObjByCode from '@/i18n/common/getLangObjByCode'
import i18n from '@/i18n'

export default function getLink(link) {
  let res = {
    external: false,
    link
  }
  if (link.startsWith('http')) {
    res.external = true
    return res
  } else if (res.link.startsWith('/')) {
    res.link =  res.link.slice(1)
  }

  let locale = res.link.split('/')[0]
  if(locale.length === 2) {
    res.link = `/${res.link}`
  } else {
    res.link = `/${getLangObjByCode(i18n.locale).show_code}/${res.link}`
  }

  return res
}