<template>
  <div class="info-modal">
    <template v-if="popupData">
      <div class="info-modal__main">
        <h3 v-if="popupData.title" class="info-modal__title">{{ popupData.title }}</h3>
        <MobApps v-if="isApp" :showTitle="false"/>
      </div>
      <template v-if="popupData.image_url">
        <a v-if="getLink(popupData.image_url).external"
           :href="getLink(popupData.image_url).link"
           @click="$vModal.close()"
           class="info-modal__img">
          <img :src="$imgPlaceholder"
               class="lazyload"
               :data-src="popupData.image" :alt="popupData.title || ''">
        </a>
        <router-link v-else
                     @click.native="$vModal.close()"
                     :to="getLink(popupData.image_url).link"
                     class="info-modal__img">
          <img :src="$imgPlaceholder"
               class="lazyload"
               :data-src="popupData.image" :alt="popupData.title || ''">
        </router-link>
      </template>
      <div v-else-if="popupData.image"
           class="info-modal__img">
        <img :src="$imgPlaceholder"
             class="lazyload"
             :data-src="popupData.image" :alt="popupData.title || ''">
      </div>
      <div v-if="popupData.description"
           class="info-modal__text">
        {{ popupData.description }}
      </div>
    </template>
    <div v-else class="info-modal__main">No data</div>
  </div>
</template>

<script>
  import MobApps from '@/components/MobApps'
  import getLink from '@/helpers/getLink'

  export default {
    name: 'InfoPopup',
    props: {
      popupData: null
    },
    components: {MobApps},
    data() {
      return {
        getLink
      }
    },
    computed: {
      isApp() {
        return this.$get(this.popupData, 'slug', '') === 'app'
      }
    }
  }
</script>

<style>
</style>